.ion-flex-offset-app, ion-content {
    height: 100%;
    overflow-y: hidden;
}

.ion-page {
    display: none;
    flex-grow: 1;
    overflow-y: hidden;
}

.chat-list {
    display: flex !important;
    flex-grow: 1 !important;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* class="inner-scroll scroll-y" */

ion-content::-webkit-scrollbar,
.chat-list::-webkit-scrollbar {
    display: none;
}

ion-content,
.chat-list {
    scrollbar-width: none;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.chat-list::-webkit-scrollbar {
    display: none; /* Hides scrollbar */
}


.ion-content-scroll-host,
.chat-item,
.chat-card-item {
    overflow-y: auto; /* Allows scrolling */
    -ms-overflow-style: none; /* Hides scrollbar in IE/Edge */
    scrollbar-width: none; /* Hides scrollbar in Firefox */
}

.ion-content-scroll-host::-webkit-scrollbar,
.chat-item::-webkit-scrollbar,
.chat-card-item::-webkit-scrollbar {
    display: none; /* Hides scrollbar */
}

ion-content::-webkit-scrollbar {
    display: none;
}

ion-content {
    -ms-overflow-style: none;
    scrollbar-width: none !important;
}

.ion-list {
    display: flex !important;
    flex-grow: 1 !important;
    overflow-y: hidden;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.custom-chat-input-col {
    padding: 10px; /* Optional: Adjust padding as needed */
}

.custom-chat-input {
    width: 100%;
    height: 100%; /* Adjust height as needed */
    border-radius: 20px; /* Rounded corners */
    background-color: #fff; /* Background color */
    padding: 10px; /* Inner padding */
    /* Additional styling as needed */
}

.icon-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-container {
    display: flex; /* Enables flexbox */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: 100%; /* Full height of the column */
}

.bulletin-board-selection-title {
    text-align: center;
    justify-content: center;
    display: flex;
    word-wrap: break-word;
    white-space: normal;
    align-items: center;
    font-size: 1.5rem !important;
    background-color: #f8f8f8;
}

.cross-post-label {
    text-align: center;
    justify-content: center;
    display: none;
    word-wrap: break-word;
    white-space: normal;
    align-items: center;
}

.chat-list {
    flex-grow: 1;
    display: none;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.chat-list::-webkit-scrollbar {
    display: none;
}

.chat-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    flex-grow: 1;  

}

.chat-input-form {
    display: block; 
}

.bulletinboard-title {
    text-align: center;
}


.avatarChat .avatarChat.small.md {
    /* allow the avatar to shrink when needed */
    flex-shrink: 1;
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
}

.chat-message-wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
}

.chat-message {
    background-color: #f1f1f1;
    color: black;
    border-radius: 15px;
    padding: 10px;
    white-space: normal;
    word-wrap: break-word;
    max-width: 80%;
}

.chat-item-right {
    color: white;
    text-align: right;
    flex-direction: row-reverse !important;
    padding-top: 5px;
    padding-bottom: 5px;
}

.chat-item-left {
    text-align: left;
    flex-direction: row !important;
    padding-top: 5px;
    padding-bottom: 5px;
}

.chat-item-left .chat-message-wrapper {
    width: 10%;
    text-align: left;
    flex-direction: row !important;
}

.chat-item-right .chat-message-wrapper {
    order: -1;
    /* Reverse the order for the right-aligned items */
    background-color: #007bff;
    color: white;
    text-align: right !important;
    width: 90%;
    flex-direction: row-reverse !important;

    /* Align text to the left inside the right-aligned bubble */
}

.chat-item-right .chat-message {
    background-color: #007bff;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
}

.chat-item-left .chat-message {
    margin-right: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    /* Align to the left */
}

.chat-message-wrapper .chat-item-right .chat-message {
    margin-right: 0px;
    margin-left: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    /* Align to the left */
}

.chat-message-wrapper .chat-item-left .chat-message {
    margin-right: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    /* Align to the left */
}

.ion-content-scroll-host {
    flex-grow: 1 !important;
    overflow-y: auto;
}

.chat-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* Make it take up remaining space */
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    overflow-y: auto;
    /* Enable vertical scrolling */
    overflow-x: hidden;
    /* Disable horizontal scrolling */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chat-item::-webkit-scrollbar {
    display: none;
}


.chat-card-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* Make it take up remaining space */
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    overflow-y: auto;
    /* Enable vertical scrolling */
    overflow-x: hidden;
    /* Disable horizontal scrolling */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chat-card-item::-webkit-scrollbar {
    display: none;
}


.chat-input-form {
    justify-content: flex-start;
    width: 100%;
    padding-left: 2% !important;
    padding-bottom: 5px !important;
    position: sticky;
    top: 0;
    z-index: 100;
    background: #f8f8f8;
}

/* Additional adjustments for the infinite scroll if needed */
.ion-infinite-scroll-content {
    padding: 0;
    margin: 0;
}

.ion-infinite-scroll-content .spinner {
    width: 20px;
    height: 20px;
}

.infinite-scroll-enabled {
    display: flex !important;
    padding: 0;
    margin: 0;
}

.IonCard-containing-chat {
    max-height: calc(100% - 120px);
    /* Adjust based on the height of the chat-input-form */
    overflow-y: scroll;
    /* Enable scrolling within the card if needed */
}


.sc-ion-alert-md-h .sc-ion-alert-md-s .alert-button-group.sc-ion-alert-md-s {
    min-width: 350px !important;
}

.ion-chip-with-image {
    min-height: 30px !important;
    max-height: 100%;
}

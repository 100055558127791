/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #ffd800;
  --ion-color-primary-rgb: 255, 216, 0;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e6c700;
  --ion-color-primary-tint: #ffe066;

  /** secondary **/
  --ion-color-secondary: #000000;
  --ion-color-secondary-rgb: 0, 0, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #000000;
  --ion-color-secondary-tint: #1a1a1a;

  /** tertiary **/
  --ion-color-tertiary: #669999;
  --ion-color-tertiary-rgb: 102, 153, 153;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #598686;
  --ion-color-tertiary-tint: #79a3a3;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

   body {
    --ion-background-color: #000000; /* Black background */
    --ion-background-color-rgb: 0, 0, 0; /* Black background in RGB */
    --ion-text-color: #ffffff; /* White text for contrast */
    --ion-text-color-rgb: 255, 255, 255; /* White text in RGB */
    --ion-item-background: #000000; /* Black item backgrounds */

    /** secondary **/
    --ion-color-secondary: #000000;
    --ion-color-secondary-rgb: 0, 0, 0;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #000000;
    --ion-color-secondary-tint: #1a1a1a;

    /** tertiary **/
    --ion-color-tertiary: #669999;
    --ion-color-tertiary-rgb: 102, 153, 153;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #598686;
    --ion-color-tertiary-tint: #79a3a3;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-toolbar-color: #f2f2f2;
    --ion-toolbar-background: #ffd800;

    --ion-item-text-color: #fff;
    --ion-item-background: #000;

    --ion-text-color: #000;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

   .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 255, 216, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 0, 0, 0;

    --ion-item-background: #000000;

    --ion-card-background: #000000;
  

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  .welcome-to-bikebus h1 {
    font-size: 2.5rem;
    color: #ffd800;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .welcome-to-bikebus h3 {
    font-size: 1.3rem;
    color: #ffd800;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .welcome-to-bikebus-about h4 {
    color: #ffd800;
    line-height: 1.6;
    font-size: 1.2rem;
  }
  
  .welcome-to-bikebus-buttons {
    margin-top: 2rem;
    background-color: #ffd800;
  }

  .toolbar-container {
    background-color: #000000
  }

  .ion-buttons {
    color: #ffd800;
  }

  .ion-text {
    color: #ffd800;
  }

  .ion-label {
    color: #ffd800;
  }



  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

   .md body {
    --ion-background-color: #807120;
    --ion-background-color-rgb: 255, 216, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 0, 0, 0;

    --ion-border-color: #222222;

    --ion-item-background: #807120;

    --ion-toolbar-background: #807120;

    --ion-tab-bar-background: #f2f2f2;

    --ion-card-background: #807120;

    --ion-alert-background: #f2f2f2;
  

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
  }
}
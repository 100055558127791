@font-face {
  font-family: 'Indie-Flower';
  src: url('./assets/fonts/IndieFlower-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.BikeBusFont {
  font-family: 'Indie-Flower', sans-serif;
}

.ion-padding-top {
  padding-top: var(--ion-safe-area-top);
}

/* This style is meant for handling iOS offsets for the notch */

@media only screen and (max-width: 768px) {
  .ion-flex-offset-app-header {
    padding-top: calc(0px + var(--ion-safe-area-top, 36px));
    background-color: #ffd800;
  }
}


.ion-flex-offset-app {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: calc(62px + var(--ion-safe-area-top, 30px));
  overflow: hidden;
}


.static-map-event {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
}


@media (max-width: 768px) {

  /* Custom sizes for icons and buttons */
  [style*="24px"] {
    width: 18px !important;
    height: 18px !important;
  }
}

.ion-button {
  --border-radius: 20px;
}

.ion-button-profile {
  --border-radius: 20px;
}

.location-app-intro-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Indie-Flower', sans-serif;
  overflow: hidden;
  word-wrap: break-word;
}

.avatar-container-profile {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.EventTimeFont {
  font-size: .8em;
  font-family: 'Indie-Flower', sans-serif;
  display: block;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #ffd800;
}

.email-input {
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.email-button {
  width: 100%;
  margin: 10px;
  border: 1px solid #ccc;
}

.forgot-password-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.use-google,
.use-anonymously,
.signup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.bikebusname-button-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 10px;
  /* optional: for some space from the bottom */
}


.avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.avatar.extrasmall {
  width: 26px;
  height: 26px;
}

.avatar.small {
  width: 50px;
  height: 50px;
}

.avatar.medium {
  width: 64px;
  height: 64px;
}

.avatar.large {
  width: 150px;
  height: 150px;
}

.bikebus-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 15px;
  /* Adjust padding as needed */
}

.footer-content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.button {
  padding: 0;
  margin: 0;
  border: none;
  /* If you don't want button to have border */
  background: none;
  /* If you don't want button to have background */
}


.map-button-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.bikebus-action-sheet {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bikebus-start-button {
  display: flex;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
}


.welcome-to-bikebus {
  justify-content: center;
  text-align: center;
  color: #ffd800;
  font-size: 3rem;
  font-family: 'Indie-Flower', sans-serif;
}

.welcome-to-bikebus-about {
  display: flex;
  justify-content: center;
  text-align: center;
}

.welcome-to-bikebus-buttons {
  justify-content: center;
  align-items: center;
  display: inline;
}

.img-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-popover {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  position: static;
  --height: 50vw;
  width: var(--width);
  height: var(--height);
  max-width: var(--width);
  max-height: var(--height);
}

.gridContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.location-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 80px;
  height: 80%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.toggle-bikebus-container {
  position: fixed;
  width: max-content;
  z-index: 1000;
  background-color: transparent;
  border: 1px solid transparent;
  width: 155px;
  outline: none;
  left: 0;
  bottom: 85px;
}

.toggle-bikebus-container IonLabel {
  background-color: rgba(255, 255, 255, 0.5);
  /* semi-transparent white */
  padding: 2px 4px;
  /* some padding to give the background some space around the text */
  border-radius: 4px;
  /* rounded corners for the background */
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
  /* more prominent shadow */
}

.toggle-bicycling-map-layer {
  position: fixed;
  width: max-content;
  z-index: 1000;
  background-color: transparent;
  border: 1px solid transparent;
  width: 175px;
  outline: none;
  right: 0;
  bottom: 85px;
}

.toggle-bicycling-map-layer IonLabel {
  background-color: rgba(255, 255, 255, 0.5);
  /* semi-transparent white */
  padding: 2px 4px;
  /* some padding to give the background some space around the text */
  border-radius: 4px;
  /* rounded corners for the background */
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
  /* more prominent shadow */
}


.end-open-trip {
  position: fixed;
  width: max-content;
  z-index: 1000;
  background-color: transparent;
  border: 1px solid transparent;
  width: 155px;
  outline: none;
  left: 0;
}

.info-window-content {
  padding: 1em;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.trip-endpoint-title {
  color: #333;
  font-size: 1.2em;
  margin-bottom: 0.5em;
}

.btn {
  color: #fff;
  border: none;
  padding: 0.5em 1em;
  text-decoration: none;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s ease;
}

.btn:focus {
  outline: 1px solid #aaa;
  /* Replace the default focus style */
}

.join-trip-btn {
  background-color: #007BFF;
  margin-right: 0.5em;
}

.join-trip-btn:hover,
.join-trip-btn:focus {
  background-color: #0056b3;
}

.focus-leader-btn {
  background-color: #28a745;
}

.focus-leader-btn:hover,
.focus-leader-btn:focus {
  background-color: #1c7430;
}



.my-location-container {
  position: fixed;
  z-index: 1000;
  background-color: white;
  border: 1px solid transparent;
  width: 80px;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
  left: 0;
  bottom: 170px;
}

.current-location {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.search-container {
  position: absolute;
  width: 380px;
  z-index: 1000;
  background-color: white;
  border: 1px solid transparent;
  height: auto;
  padding: 2px 12px;
  outline: none;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

.start-location-selected {
  position: absolute;
  width: max-content;
  z-index: 1000;
  background-color: white;
  border: 1px solid transparent;
  width: 380px;
  height: 120px;
  padding: 2px 12px;
  outline: none;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.end-location-selected {
  position: absolute;
  width: max-content;
  z-index: 1000;
  background-color: white;
  border: 1px solid transparent;
  width: 380px;
  height: 120px;
  padding: 2px 12px;
  outline: none;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.travel-button-row {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  bottom: 0;
  left: 0;
  right: 0;
  width: 380px;
}

.destination-box {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .search-container {
    top: 1vh;
    width: 80%;
  }
}


.map-base {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.home-map-flex-container {
  display: flex;
  flex-direction: column;
  height: 79vh;
  /* default height */
  overflow: hidden;
}


.home-map-row {
  flex: 1;
  position: relative;
  overflow: hidden;
}

/* General Styles for the Event Details */
.bikebus-event-name,
.bikebus-event-route,
.bikebus-startPoint,
.bikebus-endPoint,
.bikebus-stops,
.bikebus-event-time,
.bikebus-event-startAddress,
.bikebus-event-endAddress {
  border-radius: 25px;
  /* Rounded corners*/
  margin: 0px;
  padding: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  /* Darker shadow to contrast with yellow */
  display: inline-block;
  /* Ensure the element shrinks to fit content */
  word-wrap: break-word;
  /* make background behind text blurry so as to make the text more readable */
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

}

/* Specific Styles for Editing Route Details */
.toggle-bicycle-layer {
  margin: 0px;
  padding: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  /* Darker shadow to contrast with yellow */
  display: inline-block;
  /* Ensure the element shrinks to fit content */
  word-wrap: break-word;
  /* make background behind text blurry so as to make the text more readable */
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

/* Specific Styles for Each Element */
.bikebus-event-name {
  font-family: 'indie-flower', sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  /* Dark text color */
  word-wrap: break-word;
  ;
}

.bikebus-event-route {
  font-size: 16px;
  color: #000;
  word-wrap: break-word;
}

.bikebus-event-time {
  font-size: 16px;
  color: #000;
}

.bikebus-event-startAddress {
  font-size: 16px;
  color: #000;
}

.bikebus-event-endAddress {
  font-size: 16px;
  color: #000;
}

.bikebus-event-qrcode {
  background: white;
  padding: 16px;
  display: flex;
  justify-content: left;
  bottom: 85px !important;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.custom-marker-label-event {
  background-color: #ffd800;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  display: inline-block;
  word-wrap: break-word;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

@media print {
  .print-hidden {
    visibility: visible !important;
    display: block !important;
    opacity: 1 !important;
  }
}


/* Style for the map container */
.map-container {
  position: relative;
}

/* Base styles for route-info-box */
.route-info-box {
  padding: 10px;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  max-width: 300px;
  /* Maximum width, but it can shrink */
  width: auto;
  /* By default, it takes as much space as needed */
}


.route-info-box-close {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 16px;
  /* Adjust size accordingly */
  width: 16px;
  /* Adjust size accordingly */
  cursor: pointer;
}



/* Style to overlay the event details on the map */
.event-overlay {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 250px;
  /* Fixed width for the flyer */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  /* Subtle gradient */
  border: 2px solid #333;
  /* Outer border */
  border-radius: 10px;
}



.right-align {
  text-align: right;
}

.left-align {
  text-align: left;
}

.button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border: none;
  background: none;
}

.group-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.icon-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  padding-left: 0;
}

.event-map {
  position: absolute;
  height: 440px;
  width: 100%;
  overflow: hidden;
}

.sidebar-container {
  position: absolute;
  top: 0;
  right: -380px; /* Adjusted to match the sidebar width */
  padding-top: 10px;
  transition: right 0.3s ease;
  z-index: 1000;
  border-radius: 0 0 0 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.toggle-button {
  position: absolute;
  left: -60px; /* Adjusted to ensure the button is fully hidden */
  top: 10px;
  background-color: #ffffff;
  border-radius: 50%;
  width: 50px; /* Make sure this width is accounted for in the left positioning */
  height: 50px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.sidebar-container.open {
  right: 0;
  padding-top: 10px;
  height: auto;
  /* Adjust height to auto */
}

.sidebar-content {
  width: 380px;
  height: 450px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 0 10px;
  overflow-y: auto;

  /* Add scrolling if content is too long */
}

.content-wrapper {
  padding: 20px;
  box-sizing: border-box;
}



.sidebar-content .zoom-controls,
.sidebar-content .map-type-controls {
  /* Apply to both zoom and map type controls */
  display: flex;
  flex-direction: column;
  /* Stack vertically */
  align-items: flex-start;
  margin-bottom: 10px;
}

.sidebar-content .map-type-controls IonButton {
  margin: 3px 0;
  width: 100%;
  /* Full width of the container */
}

.sidebar-content IonButton {
  margin: 5px 0;
}

.sidebar-content IonText {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.sidebar-content IonLabel {
  font-size: 14px;
}

.sidebar-content IonRow {
  margin: 10px 0;
  display: flex;
  align-items: center; /* Center vertically */
}

.sidebar-content IonCol {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

/* Specific style for the label column to align the text to the right */
.sidebar-content .label-col {
  justify-content: flex-end;
  padding-right: 8px; /* Adds some space between the label and the checkbox */
}

/* Specific style for the column that holds checkboxes to align checkbox to the right */
.sidebar-content .checkbox-col {
  justify-content: flex-start;
}

/* Specific style for the column that holds icons to prevent stretching */
.sidebar-content .icon-col {
  justify-content: center;
  flex: 0 0 auto; /* Do not grow or shrink */
}


.bikebus-event-title {
  font-size: 28px;
}

/* news.tsx */

.article-title {
  white-space: normal; /* Allow wrapping */
  overflow-wrap: break-word; /* Break words to prevent overflow */
}

.sticky-row {
  position: sticky;
  top: 0;
  background-color: #f1f1f1;
  z-index: 1;
  overflow: break-word;
  white-space: normal;
}

/* welcome.tsx */



.welcome-to-bikebus h1 {
  font-size: 2.5rem;
  color: #111;
  font-weight: bold;
  margin-bottom: 1rem;
}

.welcome-to-bikebus h3 {
  font-size: 1.3rem;
  color: #111;
  font-weight: bold;
  margin-bottom: 1rem;
}

.welcome-to-bikebus-buttons {
  margin-top: 2rem;
}

.welcome-to-bikebus-buttons-google ion-button {
  margin-right: 1rem;
  --padding-start: 0em;
  --padding-end: 0em;
}



.welcome-grid {
  max-width: 500px; /* You can set a max-width to prevent the grid from stretching too wide on larger screens */
  margin: auto;
}

.IonButton {
  margin: 8px 0; /* Spacing between buttons */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  width: 100%; /* Make the button expand to the full width of the column */
}

.IonButton img {
  margin-right: 8px;
  vertical-align: middle; /* Align the image vertically with the text */
}

h1, h4 {
  text-align: center; /* Center text in the header and subheader */
  margin: 16px 0;
}

.google-sign-in {
  cursor: pointer;
  display: block;
  width: 100%; /* Adjust as needed */
  margin: 16px auto;
}

.privacy-policy-link {
  display: block;
  text-align: center;
  color: #495f73;
  padding: 16px;
  text-decoration: none;
}

.responsive-iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsive-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* login.tsx */

.img-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

/* help.tsx */

.message-page-content {
  --background: #f4f5f8;
}

.message-card {
  --background: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.message-send-button {
  --background: #3880ff;
  --color: white;
  margin-top: 16px;
}

.message-email-item ion-label,
.message-text-item ion-label {
  --color: #555;
}

.message-email-item ion-input,
.message-text-item ion-input {
  --placeholder-color: #ccc;
}


/* /* Directory.css */

.ion-accordion-group {
  border-top: 1px solid #ccc;
}

.ion-accordion-group .ion-item-divider {
  background-color: #f8f8f8;
  color: #333;
  font-weight: bold;
  padding-left: 20px;
}

.ion-accordion-group .ion-item {
  --padding-start: 20px;
  --inner-padding-end: 20px; 
}

.ion-accordion-group .ion-label {
  --text-color: #000;
  --font-size: 1rem;
}

.ion-accordion-group .ion-item[slot="header"] {
  background-color: #e2e2e2; /* Light grey background for headers */
  border-bottom: 1px solid #ccc;
}

.ion-accordion-group .ion-item[slot="content"] .ion-item {
  background-color: #e2e2e2
}

.ion-accordion-group .ion-item[slot="content"] .ion-item h2 {
  font-size: 0.9rem;
  font-weight: normal;
}

.ion-accordion-group .ion-item[slot="content"] .ion-item p {
  font-size: 0.8rem;
  color: #666;
}

.ion-accordion-group .ion-item[slot="content"]:last-child {
  margin-bottom: 10px;
}

.ion-list[slot="content"] .ion-item {
  --padding-start: 20px;
  --inner-padding-end: 20px; 
  background-color: #e2e2e2;
} 

.button-modify {
  --background: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary-tint);
  --border-radius: 8px; /* Rounded borders for buttons */
}